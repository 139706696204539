import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { MixesHttpService } from '../mixes-http/mixes-http.service';
import { ListModel } from '../../../shared/models/list/list.model';
import { MixtureListItemModel } from '../../models/mixture-list-item/mixture-list-item.model';
import { MixtureListItemCreator } from '../../creators/mixture-list-item/mixture-list-item.creator';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { MixtureListItemDto } from '../../dtos/mixture-list-item/mixture-list-item.dto';
import { ListCreator } from '../../../shared/creators/list/list.creator';
import { EditMixtureFormModel } from '../../models/edit-mixture-form/edit-mixture-form.model';
import { EditMixtureFormDto } from '../../dtos/edit-mixture-form/edit-mixture-form.dto';
import { EditMixtureFormDtoCreator } from '../../creators/edit-mixture-form-dto/edit-mixture-form-dto.creator';
import { AddMixtureFormModel } from '../../models/add-mixture-form/add-mixture-form.model';
import { AddMixtureFormDto } from '../../dtos/add-mixture-form/add-mixture-form.dto';
import { AddMixtureFormDtoCreator } from '../../creators/add-mixture-form-dto/add-mixture-form-dto.creator';
import { AssignMixtureToRepairsDto } from '../../dtos/assign-mixture-to-repairs/assign-mixture-to-repairs.dto';
import { MixtureDetailsDto } from '../../dtos/mixture-details/mixture-details.dto';
import { MixtureDetailsModel } from '../../models/mixture-details/mixture-details.model';
import { MixtureDetailsCreator } from '../../creators/mixture-details/mixture-details.creator';
import { AssignMixtureToRepairsDtoCreator } from '../../creators/assign-mixture-to-repairs-dto/assign-mixture-to-repairs-dto.creator';
import { AssignMixtureToRepairsModel } from '../../models/assign-mixture-to-repairs/assign-mixture-to-repairs.model';
import { AssignMixtureToRepairsDataPageDto } from '../../dtos/assign-mixture-to-repairs-data-page/assign-mixture-to-repairs-data-page.dto';
import { AssignMixtureToRepairsDataPageModel } from '../../models/assign-mixture-to-repairs-data-page/assign-mixture-to-repairs-data-page.model';
import { AssignMixtureToRepairsDataPageDtoCreator } from '../../creators/assign-mixture-to-repairs-data-page-dto/assign-mixture-to-repairs-data-page-dto.creator';
import { RepairAssignedToMixtureListDto } from '../../dtos/repair-assigned-to-mixture-list/repair-assigned-to-mixture-list.dto';
import { RepairAssignedToMixtureListModel } from '../../models/repair-assigned-to-mixture-list/repair-assigned-to-mixture-list.model';
import { RepairAssignedToMixtureListCreator } from '../../creators/repair-assigned-to-mixture-list/repair-assigned-to-mixture-list.creator';
import { MixtureLayersWithIngredientsAndConfigurationsModel } from '../../models/mixture-layers-with-ingredients-and-configurations/mixture-layers-with-ingredients-and-configurations.model';
import { PrepareMixtureConfigurationsFiltersDto } from '../../dtos/prepare-mixture-configurations-filters/prepare-mixture-configurations-filters.dto';
import { MixtureLayersWithIngredientsAndConfigurationsDto } from '../../dtos/mixture-layers-with-ingredients-and-configurations/mixture-layers-with-ingredients-and-configurations.dto';
import { PrepareMixtureConfigurationsFiltersModel } from '../../models/prepare-mixture-configurations-filters/prepare-mixture-configurations-filters.model';
import { PrepareMixtureConfigurationsFiltersDtoCreator } from '../../creators/prepare-mixture-configurations-filters-dto/prepare-mixture-configurations-filters-dto.creator';
import { MixtureLayersWithIngredientsAndConfigurationsCreator } from '../../creators/mixture-layers-with-ingredients-and-configuration/mixture-layers-with-ingredients-and-configurations.creator';
import { MixtureSourceDetailsModel } from '../../models/mixture-source-details/mixture-source-details.model';
import { MixtureSourceDetailsCreator } from '../../creators/mixture-source-details/mixture-source-details.creator';
import { MixtureSourceDetailsDto } from '../../dtos/mixture-source-details/mixture-source-details.dto';
import { MixtureFormulaIdFormDto } from '../../dtos/mixture-formula-id-form/mixture-formula-id-form.dto';
import { MixtureFormulaIdFormModel } from '../../models/mixture-formula-id-form/mixture-formula-id-form.model';
import { MixtureFormulaIdFormDtoCreator } from '../../creators/mixture-formula-id-form-dto/mixture-formula-id-form-dto.creator';
import { MixtureAmountAndMethodFormModel } from '../../models/mixture-amount-and-method-form/mixture-amount-and-method-form.model';
import { MixtureAmountAndMethodFormDtoCreator } from '../../creators/mixture-amount-and-method-form-dto/mixture-amount-and-method-form-dto.creator';
import { MixtureAmountAndMethodFormDto } from '../../dtos/mixture-amount-and-method-form/mixture-amount-and-method-form.dto';
import { RepairAssignedToMixtureModel } from '../../models/repair-assigned-to-mixture/repair-assigned-to-mixture.model';
import { RepairAssignedToMixtureDto } from '../../dtos/repair-assigned-to-mixture/repair-assigned-to-mixture.dto';
import { RepairAssignedToMixtureCreator } from '../../creators/repair-assigned-to-mixture/repair-assigned-to-mixture.creator';
import { MixturePricesFiltersModel } from '../../models/mixture-prices-filters/mixture-prices-filters.model';
import { MixturePricesFiltersDto } from '../../dtos/mixture-prices-filters/mixture-prices-filters.dto';
import { MixturePricesDto } from '../../dtos/mixture-prices/mixture-prices.dto';
import { MixturePricesCreator } from '../../creators/mixture-prices/mixture-prices.creator';
import { MixturePricesModel } from '../../models/mixture-prices/mixture-prices.model';
import { MixturePricesFiltersDtoCreator } from '../../creators/mixture-prices-filters-dto/mixture-prices-filters-dto.creator';
import { MixtureRecipeHistoryLayerDetailsCreator } from '../../creators/mixture-recipe-history-layer-details/mixture-recipe-history-layer-details.creator';
import { MixtureRecipeHistoryLayerDetailsDto } from '../../dtos/mixture-recipe-history-layer-details/mixture-recipe-history-layer-details.dto';
import { MixtureRecipeHistoryLayerDetailsModel } from '../../models/mixture-recipe-history-layer-details/mixture-recipe-history-layer-details.model';
import { MixtureListDataPageModel } from '../../models/mixture-list-data-page/mixture-list-data-page.model';
import { MixtureListDataPageDto } from '../../dtos/mixture-list-data-page/mixture-list-data-page.dto';
import { MixtureListDataPageDtoCreator } from '../../creators/mixture-list-data-page-dto/mixture-list-data-page-dto.creator';
import { MixtureMeasurementListDataPageDto } from '../../dtos/mixture-measurement-list-data-page/mixture-measurement-list-data-page.dto';
import { MixtureMeasurementListItemDto } from '../../dtos/mixture-measurement-list-item/mixture-measurement-list-item.dto';
import { MixtureMeasurementListItemModel } from '../../models/mixture-measurement-list-item/mixture-measurement-list-item.model';
import { MixtureMeasurementListDataPageModel } from '../../models/mixture-measurement-list-data-page/mixture-measurement-list-data-page.model';
import { MixtureMeasurementListDataPageDtoCreator } from '../../creators/mixture-measurement-list-data-page-dto/mixture-measurement-list-data-page-dto.creator';
import { MixtureMeasurementListItemCreator } from '../../creators/mixture-measurement-list-item/mixture-measurement-list-item.creator';
import { WorkshopMixingConfigurationDto } from '../../dtos/workshop-mixing-configuration/workshop-mixing-configuration.dto';
import { WorkshopMixingConfigurationModel } from '../../models/workshop-mixing-configuration/workshop-mixing-configuration.model';
import { WorkshopMixingConfigurationDtoCreator } from '../../creators/workshop-mixing-configuration-dto/workshop-mixing-configuration-dto.creator';

@Injectable({
  providedIn: 'root',
})
export class MixesService {
  constructor(private mixesHttpService: MixesHttpService) {}

  public getMixtureList(mixtureListDataPage: MixtureListDataPageModel): Observable<ListModel<MixtureListItemModel>> {
    const mixtureListDataPageDto: MixtureListDataPageDto = MixtureListDataPageDtoCreator.create(mixtureListDataPage);

    return this.mixesHttpService.getMixtureList(mixtureListDataPageDto).pipe(
      map((listDto: ListDto<MixtureListItemDto>) => {
        const items: Array<MixtureListItemModel> = listDto.items.map((dto: MixtureListItemDto) => MixtureListItemCreator.create(dto));

        return ListCreator.create<MixtureListItemDto, MixtureListItemModel>(listDto, items);
      })
    );
  }

  public editMixture(id: string, editMixtureDataModel: EditMixtureFormModel): Observable<void> {
    const editMixtureDataDto: EditMixtureFormDto = EditMixtureFormDtoCreator.create(editMixtureDataModel);

    return this.mixesHttpService.editMixture(id, editMixtureDataDto);
  }

  public addMixture(addMixtureDataModel: AddMixtureFormModel): Observable<void> {
    const addMixtureDataDto: AddMixtureFormDto = AddMixtureFormDtoCreator.create(addMixtureDataModel);

    return this.mixesHttpService.addMixture(addMixtureDataDto);
  }

  public getRepairAssignedToMixture(id: string): Observable<RepairAssignedToMixtureModel | null> {
    return this.mixesHttpService
      .getRepairAssignedToMixture(id)
      .pipe(map((dataDto: RepairAssignedToMixtureDto) => RepairAssignedToMixtureCreator.create(dataDto)));
  }

  public getRepairsAssignedToMixture(
    id: string,
    assignMixtureToRepairsDataPageModel: AssignMixtureToRepairsDataPageModel
  ): Observable<RepairAssignedToMixtureListModel> {
    const assignMixtureToRepairsDataPageDto: AssignMixtureToRepairsDataPageDto =
      AssignMixtureToRepairsDataPageDtoCreator.create(assignMixtureToRepairsDataPageModel);

    return this.mixesHttpService
      .getRepairsAssignedToMixture(id, assignMixtureToRepairsDataPageDto)
      .pipe(map((dataDto: RepairAssignedToMixtureListDto) => RepairAssignedToMixtureListCreator.create(dataDto)));
  }

  public getMixtureDetails(mixtureId: string): Observable<MixtureDetailsModel> {
    return this.mixesHttpService
      .getMixtureDetails(mixtureId)
      .pipe(map((response: MixtureDetailsDto) => MixtureDetailsCreator.create(response)));
  }

  public assignMixtureToRepairs(mixtureId: string, mixtureRepairs: AssignMixtureToRepairsModel): Observable<void> {
    const mixtureRepairsDto: AssignMixtureToRepairsDto = AssignMixtureToRepairsDtoCreator.create(mixtureRepairs);

    return this.mixesHttpService.assignMixtureToRepairs(mixtureId, mixtureRepairsDto);
  }

  public editAssignedMixtureToRepairs(mixtureId: string, repairsAssignedToMixture: AssignMixtureToRepairsModel): Observable<void> {
    const repairsAssignedToMixtureDto: AssignMixtureToRepairsDto = AssignMixtureToRepairsDtoCreator.create(repairsAssignedToMixture);

    return this.mixesHttpService.editAssignedMixtureToRepairs(mixtureId, repairsAssignedToMixtureDto);
  }

  public getMixtureLayersWithIngredientsAndConfiguration(
    mixtureId: string,
    prepareMixtureConfigurationsFilters: PrepareMixtureConfigurationsFiltersModel
  ): Observable<MixtureLayersWithIngredientsAndConfigurationsModel> {
    const prepareMixtureConfigurationsFormDto: PrepareMixtureConfigurationsFiltersDto =
      PrepareMixtureConfigurationsFiltersDtoCreator.create(prepareMixtureConfigurationsFilters);

    return this.mixesHttpService
      .getMixtureLayersWithIngredientsAndConfiguration(mixtureId, prepareMixtureConfigurationsFormDto)
      .pipe(
        map((response: MixtureLayersWithIngredientsAndConfigurationsDto) =>
          MixtureLayersWithIngredientsAndConfigurationsCreator.create(response)
        )
      );
  }

  public getMixtureSourceDetails(mixtureId: string): Observable<MixtureSourceDetailsModel> {
    return this.mixesHttpService
      .getMixtureSourceDetails(mixtureId)
      .pipe(map((dto: MixtureSourceDetailsDto) => MixtureSourceDetailsCreator.create(dto)));
  }

  public updateMixtureFormulaId(mixtureId: string, mixtureFormulaIdFormModel: MixtureFormulaIdFormModel): Observable<void> {
    const mixtureFormulaIdFormDto: MixtureFormulaIdFormDto = MixtureFormulaIdFormDtoCreator.create(mixtureFormulaIdFormModel);

    return this.mixesHttpService.updateMixtureFormulaId(mixtureId, mixtureFormulaIdFormDto);
  }

  public updateMixtureAmountAndMethod(mixtureId: string, mixtureAmountFormModel: MixtureAmountAndMethodFormModel): Observable<void> {
    const mixtureAmountFormDto: MixtureAmountAndMethodFormDto = MixtureAmountAndMethodFormDtoCreator.create(mixtureAmountFormModel);

    return this.mixesHttpService.updateMixtureAmountAndMethod(mixtureId, mixtureAmountFormDto);
  }

  public getMixturePrices(id: string, mixturePriceFilters: MixturePricesFiltersModel): Observable<MixturePricesModel> {
    const mixturePriceFiltersDto: MixturePricesFiltersDto = MixturePricesFiltersDtoCreator.create(mixturePriceFilters);

    return this.mixesHttpService
      .getMixturePrices(id, mixturePriceFiltersDto)
      .pipe(map((dto: MixturePricesDto) => MixturePricesCreator.create(dto)));
  }

  public getMixtureHistoryLayerDetails(mixtureId: string): Observable<MixtureRecipeHistoryLayerDetailsModel> {
    return this.mixesHttpService
      .getMixtureHistoryLayerDetails(mixtureId)
      .pipe(map((dto: MixtureRecipeHistoryLayerDetailsDto) => MixtureRecipeHistoryLayerDetailsCreator.create(dto)));
  }

  public getMixtureMeasurementList(
    mixtureMeasurementListDataPage: MixtureMeasurementListDataPageModel
  ): Observable<ListModel<MixtureMeasurementListItemModel>> {
    const mixtureMeasurementListDataPageDto: MixtureMeasurementListDataPageDto =
      MixtureMeasurementListDataPageDtoCreator.create(mixtureMeasurementListDataPage);

    return this.mixesHttpService.getMixtureMeasurementList(mixtureMeasurementListDataPageDto).pipe(
      map((listDto: ListDto<MixtureMeasurementListItemDto>) => {
        const items: Array<MixtureMeasurementListItemModel> = listDto.items.map((dto: MixtureMeasurementListItemDto) =>
          MixtureMeasurementListItemCreator.create(dto)
        );

        return ListCreator.create<MixtureMeasurementListItemDto, MixtureMeasurementListItemModel>(listDto, items);
      })
    );
  }

  public addWorkshopMixingConfiguration(workshopMixingConfiguration: WorkshopMixingConfigurationModel): Observable<void> {
    const workshopMixingConfigurationDto: WorkshopMixingConfigurationDto =
      WorkshopMixingConfigurationDtoCreator.create(workshopMixingConfiguration);

    return this.mixesHttpService.addWorkshopMixingConfiguration(workshopMixingConfigurationDto);
  }
}
