import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { RecipeFormulaDetailsModel } from '../../models/recipe-formula-details/recipe-formula-details.model';
import { RecipeTypeModel } from '../../models/recipe-type/recipe-type.model';
import { RecipeTypeEnum } from '../../../recipes/enums/recipe-type/recipe-type.enum';

@Component({
  selector: 'app-recipe-details-info[details]',
  templateUrl: './recipe-details-info.component.html',
  styleUrls: ['./recipe-details-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipeDetailsInfoComponent {
  @Input() public details!: RecipeFormulaDetailsModel;
  @Input() public showLinkedColorAlert: boolean;

  @Input() public set selectedMixtureType(value: RecipeTypeModel) {
    this.setShowRecipeDateInFirstColumn(value);
  }

  public showRecipeDateInFirstColumn: boolean;

  constructor() {
    this.showLinkedColorAlert = false;
    this.showRecipeDateInFirstColumn = true;
  }

  private setShowRecipeDateInFirstColumn(value: RecipeTypeModel): void {
    if (value === RecipeTypeEnum.weightedRecipe || value === RecipeTypeEnum.correction) {
      this.showRecipeDateInFirstColumn = true;
      return;
    }

    this.showRecipeDateInFirstColumn = false;
  }
}
