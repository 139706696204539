import { WorkshopMixingConfigurationDto } from '../../dtos/workshop-mixing-configuration/workshop-mixing-configuration.dto';
import { WorkshopMixingConfigurationModel } from '../../models/workshop-mixing-configuration/workshop-mixing-configuration.model';

export class WorkshopMixingConfigurationDtoCreator {
  public static create(model: WorkshopMixingConfigurationModel): WorkshopMixingConfigurationDto {
    return {
      mixture_id: model.mixtureId,
      rfu: model.isRfu,
    };
  }
}
